"use client";
import type { Decision } from "api/ads/types";
import type { Price, Product } from "api/products/types";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { resolver as adsResolver } from "api/ads";
import { cn } from "../../utils";
import { gtmEvent } from "utils/gtm";
import { ProductCardNew } from "../molecules/product-card-new";
import { useVisibility } from "../../hooks/use-visibility";

interface NativeAdProps {
  ad?: Decision | null;
  className?: string;
}

const NativeAd = memo(({ ad, className }: NativeAdProps) => {
  const { ref, inView } = useInView({ threshold: 0.5 });
  const [impressionEventFired, setImpressionEventFired] = useState(false);
  const [viewEventFired, setViewEventFired] = useState(false);
  const tabActive = useVisibility();
  const viewTimer = useRef<NodeJS.Timeout>();

  const sendDeliveredEvent = async () => {
    if (!ad) return;

    gtmEvent(`kevel_native_ad_delivered`, ad);
    await adsResolver.sendImpression(ad.impressionUrl);

    console.log(ad.adId, "Sending delivered event");
  };

  const sendViewEvent = async () => {
    if (!ad) return;

    gtmEvent("kevel_display_ad_inview", ad);

    const viewEvent = ad.events.find(({ id }) => id === 40);
    if (!viewEvent) return;

    await adsResolver.sendImpression(viewEvent.url);
    console.log(ad.adId, "Sending view event");
  };

  useEffect(() => {
    if (!ad) return;

    if (!impressionEventFired) {
      void sendDeliveredEvent();
      setImpressionEventFired(true);
    }

    if (inView && tabActive) {
      if (!viewEventFired)
        viewTimer.current = setTimeout(() => {
          void sendViewEvent();
          setViewEventFired(true);
        }, 1000);
    } else {
      return clearTimeout(viewTimer.current);
    }

    return () => {
      return clearTimeout(viewTimer.current);
    };
  }, [ad, inView, tabActive, impressionEventFired, viewEventFired]);

  const currentAd = useMemo(() => {
    if (!ad) return;

    const data = ad.contents[0].data;
    let cpcValue = data.cpcValue ? data.ctCpcValue.replace(",", ".") : "1.5";

    if (data.ctProduct) {
      const product = data.ctProduct as Product;
      const prices = data.ctPrices as Price[];

      let priceLowest = product.priceLowest;

      if (data.ctShopId) {
        const shopPrice = prices.find(
          (price) => price.shopId === Number(data.ctShopId)
        );

        priceLowest = shopPrice
          ? {
              shopId: shopPrice.shopId,
              shopName: shopPrice.shop.name,
              amount: shopPrice.amount,
              link: shopPrice.link,
              id: shopPrice.ean,
              cpcRate: Number(cpcValue),
              ranking: shopPrice.ranking,
              affiliate: shopPrice.shop.affiliate,
              shopType: shopPrice.shop.type,
            }
          : null;
      }

      if (!data.ctShopId && prices.length > 0) {
        priceLowest = {
          shopId: prices[0].shopId,
          shopName: prices[0].shop.name,
          amount: prices[0].amount,
          link: prices[0].link,
          id: prices[0].ean,
          cpcRate: Number(cpcValue),
          ranking: prices[0].ranking,
          affiliate: prices[0].shop.affiliate,
          shopType: prices[0].shop.type,
        };
      }

      if (!priceLowest)
        priceLowest = {
          shopId: 0,
          shopName: "productTile",
          amount: 0,
          link: ad.clickUrl,
          id: product.eans[0],
          cpcRate: Number(cpcValue),
          ranking: "NEUTRAL",
          affiliate: false,
          shopType: "TRADITIONAL",
        };

      return (
        <ProductCardNew
          ref={ref}
          key={product.kisId}
          {...product}
          priceLowest={{
            ...priceLowest,
            shopType: "TRADITIONAL",
          }}
          slug={ad.clickUrl}
          type="product"
          thumbnail={product.media[0]}
          className={cn("h-full w-full", className)}
          buttonText={data.ctClickoutLinkText as string | undefined}
          sponsored
        />
      );
    }

    let priceText: string;
    const formattedPrice = ((data.ctPrice as string) || "0")
      .replaceAll("€", "")
      .replaceAll(".", "")
      .replaceAll(",", ".")
      .replaceAll(" ", "");

    const price = Number(formattedPrice);
    if (isNaN(price)) priceText = data.ctPrice.toString();

    return (
      <ProductCardNew
        ref={ref}
        title={data.ctTitle as string}
        slug={ad.clickUrl}
        type="product"
        thumbnail={data.ctDisplayImageUrl as string}
        linkTarget={data.ctTarget as string}
        titleText={data.ctText as string}
        className={cn("h-full w-full", className)}
        buttonText={data.ctClickoutLinkText as string | undefined}
        sponsored
        kieskeurigId={0}
        kisId={""}
        brand={""}
        priceLowest={{
          shopId: 0,
          shopName: "productTile",
          shopType: "TRADITIONAL",
          id: "0",
          link: ad.clickUrl,
          cpcRate: Number(cpcValue),
          amount: Number(formattedPrice),
          ranking: "NEUTRAL",
          affiliate: false,
        }}
        priceText={priceText}
        reviewCount={0}
        discount={0}
        aliases={[]}
        reviewScore={0}
        certificates={[]}
        family={null}
        familyId={null}
        seriesId={null}
        series={null}
        lowestPriceUntilNow={false}
        productCode={""}
        categorySlug={""}
      />
    );
  }, []);

  return currentAd;
});

NativeAd.displayName = "NativeAd";

export { NativeAd };
